import React from "react";
import DoubleBreak from "./DoubleBreak";
import Detail from "./Detail";
import sortDetails from "../methods/Sorting";

const DetailsVertical = ({ details }) => {
    return (
      <div className="details-vertical">
        {sortDetails(details).map((detail, index) => (
          <React.Fragment key={index}>
            <Detail text={detail.text || detail} link={detail.link} tooltip={detail.tooltip} />
            <DoubleBreak />
          </React.Fragment>
        ))}
      </div>
    );
};

export default DetailsVertical;