import React from "react";

const DoubleBreak = () => {
    return (
      <>
        <br />
        <br />
      </>
    );
  };
  
export default DoubleBreak;