import React from "react";
import DoubleBreak from "./DoubleBreak";
import Detail from "./Detail";
import sortDetails from "../methods/Sorting";

const DetailsHotizontal = ({ details }) => {
  return (
    <div className="details-container">
      {sortDetails(details).map((detail, index) => (
        <React.Fragment key={index}>
          {(index !== 0 && index % 3 !== 0) && " | "}
          <Detail text={detail.text || detail} link={detail.link} tooltip={detail.tooltip} />
          {(index + 1) % 3 === 0 && <DoubleBreak />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default DetailsHotizontal