import React, { useEffect, useState } from "react";
import Card from './components/Card.js'
import Mobile from "./components/Mobile.js";
import "./styles.css";

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="container">
      {isMobile ? <Mobile /> : <Card />}
    </div>
  );
};

export default App;
