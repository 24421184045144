import React from "react";
import DetailsVertical from "./DetailsVertical";
import DoubleBreak from "./DoubleBreak";
import detailsData from "../details.json";

const Mobile = () => {
    return (
      <div className="mobile-container" style={{ textAlign: "center" }}>
        <DoubleBreak />
        <img
          src={require('../huge.png')}
          alt="DES"
          style={{ maxWidth: "238px", width: "100%", height: "auto", background: "transparent" }}
        />
        <DoubleBreak />
        <p className="name">
          <b>Daniel Emmett Schmidt</b>
        </p>
        <br/>
        <DetailsVertical details={detailsData} />
      </div>
    );
  };

  export default Mobile;