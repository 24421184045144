import React from "react";

const Detail = ({ text, link, tooltip }) => {
    return (
      <span className="details">
        {link ? <a  target="_blank" rel="noopener noreferrer" href={link} title={tooltip || text}>{text}</a> : text}
      </span>
    );
};

export default Detail;