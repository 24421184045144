import React from "react";
import DetailsHotizontal from "./DetailsHotizontal.js"
import detailsData from "../details.json";

const Card = () => {
    return (
      <div className="card">
        <div className="wrapper">
          <div className="block">
            <div className="left">
              <img
                src={require('../huge.png')}
                alt="DES"
                width="238"
                height="92"
              />
            </div>
            <div className="right">
              <div className="titlebreaka" />
              <p className="name">
                <b>Daniel Emmett Schmidt</b>
              </p>
              <div className="titlebreakb" />
            </div>
          </div>
          <div className="break" />
          <div className="bottom">
            <DetailsHotizontal details={detailsData} />
          </div>
        </div>
      </div>
    );
};

export default Card;